




























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ExpansionPanel, Table } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * MaterialsExpansionPanelList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'materials-expansion-panel-form-dialog': () => import('./MaterialsExpansionPanelFormDialog.vue')
    }
})
export default class MaterialsExpansionPanelList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all materialsExpansionPanels
    @Prop({ type: Array as () => Array<ExpansionPanel<Table>> }) readonly materialsExpansionPanels: Array<ExpansionPanel<Table>> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited materialsExpansionPanel
    private editedMaterialsExpansionPanelItem: ExpansionPanel<Table> = {
        id: -1,
        header: '',
        content: {
            id: -1,
            headers: [],
            data: []
        }
    };

    // Default materialsExpansionPanel item values
    private defaultMaterialsExpansionPanelItem: ExpansionPanel<Table> = {
        id: -1,
        header: '',
        content: {
            id: -1,
            headers: [],
            data: []
        }
    }

    // The index of the edited materialsExpansionPanel (-1 = new materialsExpansionPanel)
    private editedMaterialsExpansionPanelIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.header').toString(),
                value: 'header'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the MaterialsExpansionPanelFormDialog to create a new materialsExpansionPanel
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addMaterialsExpansionPanel() {
        // get max id
        const customerInformationId = (this.materialsExpansionPanels && this.materialsExpansionPanels.length >= 1) ? this.materialsExpansionPanels[this.materialsExpansionPanels.length -1].id +1 : 0;

        // FIXME spread is copying reference?
        this.editedMaterialsExpansionPanelItem = CloneHelper.deepCloneObject({...this.defaultMaterialsExpansionPanelItem, id: customerInformationId });
        this.editedMaterialsExpansionPanelIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the MaterialsExpansionPanelFormDialog to edit the given materialsExpansionPanel element
     * 
     * @param materialsExpansionPanelItem materialsExpansionPanel item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private editMaterialsExpansionPanel(materialsExpansionPanelItem: ExpansionPanel<Table>) {
        if (this.materialsExpansionPanels) {
            this.editedMaterialsExpansionPanelItem = CloneHelper.deepCloneObject(materialsExpansionPanelItem);
            this.editedMaterialsExpansionPanelIndex = this.materialsExpansionPanels.indexOf(materialsExpansionPanelItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given materialsExpansionPanel
     * 
     * @param materialsExpansionPanelItem materialsExpansionPanel item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deleteMaterialsExpansionPanel(materialsExpansionPanelItem: ExpansionPanel<Table>) {
        if (this.materialsExpansionPanels) {
            this.editedMaterialsExpansionPanelItem = CloneHelper.deepCloneObject(materialsExpansionPanelItem);
            this.editedMaterialsExpansionPanelIndex = this.materialsExpansionPanels.indexOf(materialsExpansionPanelItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedMaterialsExpansionPanel(formDialogMode: string, materialsExpansionPanelItemIndex: number, materialsExpansionPanelItem: ExpansionPanel<Table>) {
        if (this.materialsExpansionPanels) {
            if (formDialogMode == 'create') {
                this.materialsExpansionPanels.push(materialsExpansionPanelItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.materialsExpansionPanels[materialsExpansionPanelItemIndex], materialsExpansionPanelItem);
            }
            else if (formDialogMode == 'delete') {
                this.materialsExpansionPanels.splice(materialsExpansionPanelItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
